import { NO_FILTER_SELECTED_TEXT } from '../../../constants/text';

export const UPDATE_STATUSES = {
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  PUBLISHED: 'published',
  ARCHIVED: 'archived',
};

export const STATUS_FILTERS = [
  {
    text: NO_FILTER_SELECTED_TEXT,
    value: null,
  },
  {
    text: 'Draft',
    value: UPDATE_STATUSES.DRAFT,
  },
  {
    text: 'Scheduled',
    value: UPDATE_STATUSES.SCHEDULED,
  },
  {
    text: 'Published',
    value: UPDATE_STATUSES.PUBLISHED,
  },
];
